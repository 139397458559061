import { authGuard } from './core/guards/auth.guard';
import { translationGuard } from './core/guards/translation.guard';
import { ContentComponent } from './app/content/content.component';
import { dynamicRouteGuard } from './core/guards/dynamic-route.guard';
import { legacyPropertyRoutes } from './legacy.routes';
import { contextLoaderGuard } from './core/guards/context-loader.guard';
import { CustomRoutes } from './core/models/routing.model';
import { AccessUnauthorisedComponent } from './features/status-pages/components/access-unauthorised/access-unauthorised.component';
import { AppUnavailableComponent } from './features/status-pages/components/app-unavailable/app-unavailable.component';
import { TenantSwitchComponent } from './features/status-pages/components/tenant-switch/tenant-switch.component';
import { assetDeepLinkGuard } from './features/property-catalog/guards/asset-deep-link.guard';

// breadcrumbFilters takes a list of indexes of breadcrumbs not to show. Use this when a particular partial link does not make sense
// and would take user to a dead link.
// EG
export const appRoutes: CustomRoutes = [
  {
    path: 'properties/factsperproperty',
    children: legacyPropertyRoutes,
  },
  {
    path: 'app-access-unauthorised',
    component: AccessUnauthorisedComponent,
  },
  {
    path: 'app-unavailable',
    component: AppUnavailableComponent,
  },
  {
    path: 'app-tenant-switch',
    component: TenantSwitchComponent,
  },
  {
    path: 'login',
    loadChildren: () => import('./features/auth/auth.routes'),
  },
  {
    path: '',
    canActivate: [authGuard],
    component: ContentComponent,
    children: [
      {
        path: '',
        canActivate: [translationGuard],
        children: [
          {
            path: 'permalink',
            children: [
              {
                path: 'property-asset/:assetId',
                canActivate: [assetDeepLinkGuard],
                children: [],
              },
            ],
          },
          {
            path: 'property-catalog',
            loadChildren: () => import('./features/property-catalog/property-catalog.routes'),
            data: {
              breadcrumbFilters: [0],
            },
          },
          {
            path: 'applications/appsuite',
            loadChildren: () => import('./features/app-suite/app-suite.routes'),
          },
          {
            path: 'geo-module',
            canActivate: [contextLoaderGuard],
            data: { breadcrumbFilters: [0] },
            loadChildren: () => import('./features/geo/geo.routes'),
          },
          {
            path: 'orga-module',
            canActivate: [contextLoaderGuard],
            data: { breadcrumbFilters: [0] },
            loadChildren: () => import('./features/organization/orga.routes'),
          },
          {
            path: 'file-upload',
            data: { internalPath: true }, // routes used only to load a module are marked as internal paths
            loadChildren: () => import('./features/upload/upload.routes'),
          },
          {
            path: 'videos',
            data: {
              internalPath: true, // routes used only to load a module are marked as internal paths
              breadcrumbFilters: [0],
            },
            loadChildren: () => import('./features/videos/videos.routes'),
          },
          {
            path: 'iframe',
            data: {
              internalPath: true, // routes used only to load a module are marked as internal paths
              breadcrumbFilters: [0],
            },
            loadChildren: () => import('./features/iframe/iframe.routes'),
          },
          {
            path: 'staff',
            redirectTo: 'staffnode',
          },
          {
            path: 'vocabulary',
            data: { breadcrumbFilters: [0], breadcrumbTranslationFilters: [2] },
            loadChildren: () => import('./features/vocabulary/vocabulary.routes'),
          },
          {
            path: 'proca-data-quality',
            data: { breadcrumbFilters: [0] },
            loadChildren: () => import('./features/proca-data-issues/proca-data-issues.routes'),
          },
          {
            path: 'proca-responsible-persons',
            data: { breadcrumbFilters: [0] },
            loadChildren: () => import('./features/proca-responsible-persons/proca-responsible-persons.routes'),
          },
          {
            path: 'pictures',
            loadChildren: () => import('./features/pictures/pictures.routes'),
            data: { breadcrumbFilters: [0] },
          },
          {
            path: 'companies/companylist',
            loadChildren: () => import('./features/companies/companies.routes'),
          },
          {
            path: 'staffnode/catalog',
            loadChildren: () => import('./features/staff-app/core/staff-app.routes'),
          },
          {
            path: 'language',
            loadChildren: () => import('./features/language/language.routes'),
          },
          {
            path: 'system-info',
            loadChildren: () => import('./features/system-info/system-info.routes'),
          },
          {
            path: 'search',
            loadChildren: () => import('./features/advanced-search/advanced-search.routes'),
          },
          {
            path: 'document',
            data: { breadcrumbFilters: [0] },
            loadChildren: () => import('./features/documents/documents.routes'),
          },
          {
            path: 'download',
            loadChildren: () => import('./features/download/download.routes'),
          },
          {
            path: '**',
            canActivate: [dynamicRouteGuard],
            loadChildren: () => import('./features/navigation/navigation.routes'),
            data: { loadNodes: true },
          },
        ],
      },
    ],
  },
];
